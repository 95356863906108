<template>
  <div>
    <!-- 招聘 -->
    <div class="bottom_right" v-if="i == 1">
      <div class="smalltitle">招聘信息</div>
      <div class="password">
        <div class="right"><span @click="gettags(12)">发布招聘</span></div>
      </div>
      <div class="tabbox">
             <el-table :data="zhaopindata" style="width: 100%">
      
        <el-table-column label="招聘名称">
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="top">
              <p>{{ scope.row.Title }}</p>

              <div slot="reference" class="name-wrapper">
                {{ scope.row.Title }}
              </div>
            </el-popover>
          </template>
        </el-table-column>

          <el-table-column label="创建日期">
          <template slot-scope="scope">
            <i class="el-icon-time"></i>
            <span style="margin-left: 10px">{{ timeformart.timeFormat(scope.row.CreateOn,1) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="开启/关闭">
          <template slot-scope="scope">
            <div slot="reference" class="name-wrapper">
              <el-switch
                v-model="scope.row.State"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="getzhaopinopen(scope.row)"
              >
              </el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="success"
              @click="gettagszhao(10, scope.row)"
              >查看</el-button
            >
            <el-button
              size="mini"
              type="primary"
              @click="gettagszhao(11, scope.row)"
              >编辑</el-button
            >
            <el-button
              v-if="!scope.row.State"
              size="mini"
              type="danger"
              @click="deleted('/api/Advertise/Del', scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="currentSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totoPage"
        >
        </el-pagination>
      </div>
      </div>
 
    </div>
    <!-- 招聘详情 -->
    <div class="bottom_right" v-if="i == 10">
      <div class="heard">
        <span @click="gettags(1)"
          ><img src="../assets/img/back.png" alt="" /> 返回</span
        >
      </div>
      <div class="smalltitle">招聘详情</div>
      <div class="username"><span>职位名称：</span>
                <el-tooltip
            class="item"
            effect="dark"
            :content="itemzhaopin.Title"
            placement="top-start"
          >
            <i>{{ itemzhaopin.Title }}</i>
          </el-tooltip>
      </div>
      <div class="username">
        <span>招聘类型：</span>{{ itemzhaopin.TypeName }}
      </div>
      <div class="username"><span>学历：</span>{{ itemzhaopin.Education }}</div>
      <div class="username">
        <span>工作经验：</span>{{ itemzhaopin.Experience }}
      </div>
      <div class="username">
        <span>工作地址：</span>{{ itemzhaopin.Address }}
      </div>
      <div class="username">
        <span>薪资范围：</span
        >{{ itemzhaopin.SalaryL + "-" + itemzhaopin.SalaryH }}
      </div>
      <div class="username"><span>职位福利</span>{{ itemzhaopin.Tags }}</div>

      <div class="usertitle">任职要求：</div>
      <div class="htmlbox" v-html="itemzhaopin.Description"  ref="content"
          @click="imageEnlargement"></div>
    </div>
    <!-- 编辑招聘 -->
    <div class="bottom_right" v-if="i == 11">
      <div class="heard">
        <span @click="gettags(1)"
          ><img src="../assets/img/back.png" alt="" /> 返回</span
        >
      </div>
      <div class="smalltitle">编辑招聘信息</div>
      <el-form
        :model="itemzhaopin"
        :rules="zhaopinrules"
        ref="itemzhaopin"
        class="demo-ruleForm"
      >
        <el-form-item label="职位名称" prop="Title">
          <el-input v-model="itemzhaopin.Title"></el-input>
        </el-form-item>
        <el-form-item label="招聘类型" :required="trues"> </el-form-item>
        <el-form-item   prop="TypeName">
          <el-select
            v-model="itemzhaopin.TypeName"
            @change="changeType"
            placeholder="请选择"
          >
            <el-option
              v-for="item in zhaopintypelist"
              :key="item.Name"
              :label="item.Name"
              :value="item.SysID"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="福利待遇（可多选）" prop="Tags"> </el-form-item>
        <el-form-item>
          <el-select
            v-model="itemzhaopin.Tags"
            multiple
            filterable
            allow-create
            default-first-option
            @change="changeFL"
            placeholder="请选择福利待遇（可多选）"
          >
            <el-option
              v-for="item in zgaopinFL"
              :key="item.SysID"
              :label="item.Name"
              :value="item.Name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学历" :required="trues" > </el-form-item>
        <el-form-item label=""  prop="Education">
          <el-select v-model="itemzhaopin.Education" placeholder="请选择">
            <el-option
              v-for="item in Educationlist"
              :key="item.labey"
              :label="item.labey"
              :value="item.labey"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="工作经验" prop="Experience"> </el-form-item>
        <el-form-item label="" prop="Experience">
          <el-select v-model="itemzhaopin.Experience" placeholder="请选择">
            <el-option
              v-for="item in Experiencelist"
              :key="item.labey"
              :label="item.labey"
              :value="item.labey"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="工作地址" prop="Address">
          <el-input v-model="itemzhaopin.Address"></el-input>
        </el-form-item>
        <el-form-item label="薪资范围" :required="trues"> </el-form-item>
        <el-form-item label="" prop="SalaryH">
          <el-select v-model="itemzhaopin.SalaryL" placeholder="最低工资">
            <el-option
              v-for="item in moneyList"
              :key="item.labey"
              :label="item.labey"
              :value="item.labey"
            >
            </el-option>
          </el-select>
          -
          <el-select v-model="itemzhaopin.SalaryH" placeholder="最高工资">
            <el-option
              v-for="item in moneyList"
              :key="item.labey"
              :label="item.labey"
              :value="item.labey"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任职要求" > </el-form-item>
        <el-form-item >
          <editor-bar
            v-model="itemzhaopin.Description"
            @change="getEditor"
            ref="weditor"
          ></editor-bar>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="zhaopinedit('itemzhaopin')"
            >确定</el-button
          >
          <el-button @click="canclezhaopin('ruleForm')">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 发布招聘 -->
    <div class="bottom_right" v-if="i == 12">
      <div class="heard">
        <span @click="gettags(1)"
          ><img src="../assets/img/back.png" alt="" /> 返回</span
        >
      </div>
      <div class="smalltitle">发布招聘信息</div>
      <el-form
        :model="addzhaopinlist"
        :rules="zhaopinrules"
        ref="addzhaopinlist"
        class="demo-ruleForm"
      >
        <el-form-item label="职位名称" prop="Title">
          <el-input v-model="addzhaopinlist.Title"></el-input>
        </el-form-item>
        <el-form-item label="招聘类型" :required="trues" > </el-form-item>
        <el-form-item  prop="TypeName">
          <el-select v-model="addzhaopinlist.TypeName" placeholder="请选择">
            <el-option
              v-for="item in zhaopintypelist"
              :key="item.Name"
              :label="item.Name"
              :value="item.SysID"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="福利待遇（可多选）" prop="Tags"> </el-form-item>
        <el-form-item>
          <el-select
            v-model="addzhaopinlist.Tags"
            multiple
            filterable
            allow-create
            default-first-option
            placeholder="请选择福利待遇（可多选）"
          >
            <el-option
              v-for="item in zgaopinFL"
              :key="item.OrganizeSysID"
              :label="item.Name"
              :value="item.Name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学历" :required="trues" > </el-form-item>
        <el-form-item prop="Education"  label="">
          <el-select v-model="addzhaopinlist.Education" placeholder="请选择">
            <el-option
              v-for="item in Educationlist"
              :key="item.labey"
              :label="item.labey"
              :value="item.labey"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="工作经验"> </el-form-item>
        <el-form-item label="" prop="Experience">
          <el-select v-model="addzhaopinlist.Experience" placeholder="请选择">
            <el-option
              v-for="item in Experiencelist"
              :key="item.labey"
              :label="item.labey"
              :value="item.labey"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="工作地址" prop="Address">
          <el-input v-model="addzhaopinlist.Address"></el-input>
        </el-form-item>
        <el-form-item label="薪资范围" prop="SalaryL"> </el-form-item>
        <el-form-item label="" prop="SalaryH">
          <el-select v-model="addzhaopinlist.SalaryL" placeholder="最低工资">
            <el-option
              v-for="item in moneyList"
              :key="item.labey"
              :label="item.labey"
              :value="item.labey"
            >
            </el-option>
          </el-select>
          -
          <el-select v-model="addzhaopinlist.SalaryH" placeholder="最高工资">
            <el-option
              v-for="item in moneyList"
              :key="item.labey"
              :label="item.labey"
              :value="item.labey"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任职要求" > </el-form-item>
        <el-form-item>
          <editor-bar
            v-model="addzhaopinlist.Description"
            @change="getzhaopinddEditor"
            @onblue="getzhaopinIntro"
            ref="weditor"
          ></editor-bar>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="addzhaopin('addzhaopinlist')"
            >确定</el-button
          >
          <el-button @click="canclezhaopin('ruleForm')">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>

import EditorBar from "../components/wangeditor";
import timeFormat from "../util/time.js";


export default {
  components: {
    EditorBar,
  },
  data() {
    var checkAge = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("年龄不能为空"));
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error("请输入数字值"));
        } else {
          if (value < 18) {
            callback(new Error("必须年满18岁"));
          } else {
            callback();
          }
        }
      }, 1000);
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      zgaopinFL: [],
      moneyList: [
        {
          labey: "1千",
        },
        {
          labey: "2千",
        },
        {
          labey: "3千",
        },
        {
          labey: "4千",
        },
        {
          labey: "5千",
        },
        {
          labey: "6千",
        },
        {
          labey: "7千",
        },
        {
          labey: "8千",
        },
        {
          labey: "9千",
        },
        {
          labey: "10千",
        },
        {
          labey: "11千",
        },
        {
          labey: "12千",
        },
        {
          labey: "13千",
        },
        {
          labey: "14千",
        },
        {
          labey: "15千",
        },
        {
          labey: "16千",
        },
        {
          labey: "17千",
        },
        {
          labey: "18千",
        },
        {
          labey: "19千",
        },
        {
          labey: "20千",
        },
        {
          labey: "21千",
        },
        {
          labey: "22千",
        },
        {
          labey: "23千",
        },
        {
          labey: "24千",
        },
        {
          labey: "25千",
        },
        {
          labey: "26千",
        },
        {
          labey: "27千",
        },
        {
          labey: "28千",
        },
        {
          labey: "29千",
        },
        {
          labey: "30千",
        },
        {
          labey: "31千",
        },
      ],
      // 招聘类型
      zhaopintypelist: [],
      // 学历
      Educationlist: [
        {
          labey: "不限",
        },
        {
          labey: "初中及以下",
        },
        {
          labey: "高中",
        },
        {
          labey: "中专/中技",
        },
        {
          labey: "大专",
        },
        {
          labey: "本科",
        },
        {
          labey: "硕士",
        },
        {
          labey: "MBA/EMBA",
        },
        {
          labey: "博士",
        },
      ],
      // 经验
      Experiencelist: [
        {
          labey: "经验不限",
        },
        {
          labey: "无经验",
        },
        {
          labey: "1年以下",
        },
        {
          labey: "1-3年",
        },
        {
          labey: "3-5年",
        },
        {
          labey: "5-10年",
        },
        {
          labey: "10年以上",
        },
      ],
      // 营业执照
      yyzzlist: [],

      // 企业类型列表
      companyType: null,
      // 企业标签
      companyTags: null,
      value2: true,
      tableData: [
  
      ],
      //   富文本
      editor: null,

      info_: null,
      compObj: {},
      index: 1,
      i: 1,
      dialogVisible: false,
      ruleForms: {
        pass: "",
        checkPass: "",
        age: "",
      },
      ruless: {
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
        age: [{ validator: checkAge, trigger: "blur" }],
      },
      // 企业信息列表
      ruleForm: {},
      // 企业
  trues:true,
  
      // 企业信息列表

      // 招聘验证
      zhaopinrules: {
        Title: [{ required: true, message: "请输入职位名称", trigger: "change" }],
        // 招聘类型
        TypeName: [
          {
            required: true,
            message: "请选择类型",
            trigger: "change",
          },
        ],
        // 学历
        Education: [
        
            {
            required: true,
            message: "请选择学历要求",
            trigger: "change",
          },
        ],
        // 工作经验
        Experience: [
        
        ],
        // 地址
        Address: [
          { required: true, max: 50, message: "请填写地址", trigger: "change" },
        ],

        // 最低工资
        SalaryL: [
          {
            required: true,
            message: "请选择工资",
            trigger: "change",
          },
        ],
        SalaryH: [
          {
            required: true,
            message: "请选择工资",
            trigger: "change",
          },
        ],
        // 职位要求
        Description: [
        
        ],
      },

   
      value: "",
      servertypelist: [],
      addserverList: {},
      zhaopindata: [],
      itemzhaopin: {},
      gongxundata: [],
      itemgongxu: {},
      gongxu: {},
      gongxutype: [
        {
          typename: "供应",
          laby: "0",
        },
        {
          typename: "需求",
          laby: "1",
        },
      ],
      addzhaopinlist: {},
      addgongxu: {},
      companyProfession: [],
      totoPage: null,
      currentPage: 1,
      currentSize: 5,
       imgArr:[],
    };
  },

  methods: {
     imageEnlargement(e) {
   
      if (e.target.nodeName == "IMG") {
        //判断点击富文本内容为img图片
        console.log(e.target.currentSrc);
        this.imgArr[0] = e.target.currentSrc;
        console.log(this.imgArr);

         this.$viewerApi({
          images: this.imgArr
        })
     
      } else {
        console.log("点击内容不为img");
      }
    },

    // 分页
    handleSizeChange(a) {
      console.log(a);
      this.currentSize = a;
      this.getzhabiaoList();
    },
    handleCurrentChange(b) {
      console.log(b);
      this.currentPage = b;
      this.getzhabiaoList();
    },
    getradio(e) {
      console.log(e);
    },
    getindex() {
      window.localStorage.setItem("index", "1");
      this.$router.push("/");
    },
    // 获取企业信息
    getnewsList() {
      let that = this;
      let Account = window.localStorage.getItem("Account");
      that
        .$post("/api/UserInfo/ObjAccount", {
          Account: Account,
        })
        .then(function (data) {
          console.log(data);
          let company = data.Obj.CompanyObj;
          // 图片处理
          company.Logo = that.urlOA + company.Logo.substr(1);
          // 时间处理
          company.OpenTime = timeFormat.timeFormat(company.OpenTime, 1);
          company.Tags = company.Tags.split(",");
          that.ruleForm = company;
          that.compObj = company;
          console.log(EditorBar.value);
          // EditorBar.value = that.ruleForm.Description

          // 营业执照相关
          // let yyzz = data.Obj.CompanyYYZZList;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 企业类型
    gettype() {
      let that = this;
      that
        .$post("/api/Dictionary/List", {
          Type: "NewsCompanyType",
           Sort: 'Sort asc',
          State:true
        })
        .then(function (data) {
          console.log(data);
          let list = data.Obj;
          console.log(list);
          that.companyType = list;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 招聘类型
    getzhaopintype() {
      let that = this;
      that
        .$post("/api/Dictionary/List", {
          Type: "AdvertiseType",
           Sort: 'Sort asc',
          State:true
        })
        .then(function (data) {
          console.log(data);
          let list = data.Obj;
          console.log(list);
          that.zhaopintypelist = list;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 福利待遇
    getFL() {
      let that = this;
      that
        .$post("/api/Dictionary/List", {
          Type: "Benefits",
           Sort: 'Sort asc',
          State:true
        })
        .then(function (data) {
          console.log(data);
          let list = data.Obj;
          console.log(list);
          that.zgaopinFL = list;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 企业服务类型
    getservetype() {
      let that = this;
      that
        .$post("/api/Dictionary/List", {
          Type: "CompanyServeType",
           Sort: 'Sort asc',
          State:true
        })
        .then(function (data) {
          console.log(data);
          let list = data.Obj;
          console.log(list);
          that.servertypelist = list;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 企业标签
    getTags() {
      let that = this;
      that
        .$post("/api/Dictionary/List", {
          Type: "CompanyTag",
           Sort: 'Sort asc',
          State:true
        })
        .then(function (data) {
          console.log(data);
          let list = data.Obj;
          console.log(list);
          that.companyTags = list;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    cancleeditCom(i) {
      this.item = {};
      this.i = i;
    },

    deleted(url, row) {
      let that = this;
      that
        .$confirm("此操作将永久删除, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          that.$post(url, { SysID: row.SysID }).then(function (data) {
            console.log(data);
            if (data.Result) {
              that.$message({
                type: "success",
                message: "删除成功!",
              });

              // 招标
              that.getzhabiaoList();
            }
          });
        });
    },

    // 招聘的富文本编辑器
    getzhaopinddEditor(value) {
      console.log(value);
      this.addzhaopinlist.Description = value;
    },
    getzhaopinIntro(value) {
      console.log(value);
      this.addzhaopinlist.Intro = value;
    },

    // 取消编辑招聘
    canclezhaopin() {
      let that = this;
      that.i = 1;
      that.addzhaopinlist = {};
    },
    // 取消编辑互动
    canclegongxu() {
      let that = this;
      that.i = 13;
      that.gongxu = {};
    },
    // 企业富文本
    getEditor(value) {
      console.log(value);

      this.itemzhaopin.Description = value;
    },
 
    // 服务编辑富文本
    getserveEditor(value) {
      this.item.Description = value;
    },

    // 招聘列表

    getzhabiaoList() {
      let that = this;
      let CompanySysID = window.localStorage.getItem("CompanySysID");
      that
        .$post("/api/Advertise/List", {
          CompanySysID: CompanySysID,
          CState:true,
          Sort: "CreateOn desc",
          PageIndex: that.currentPage,
          PageSize: that.currentSize,
        })
        .then(function (data) {
          console.log(data);
          that.totoPage = data.RecordCount;
          let company = data.Obj;
          console.log(timeFormat.timeFormat);
          company.forEach(() => {
            // v.CreateOn = timeFormat.timeFormat(v.CreateOn, 1);
          });

          that.zhaopindata = company;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 开关招聘
    getzhaopinopen(row) {
      console.log(row);
      let that = this;
      that
        .$post("/api/Advertise/ChangeInfo", {
          SysID: row.SysID,
          State: row.State,
        })
        .then(function (data) {
          console.log(data);
          that.$message.success("操作成功", "success");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    changeFL(value) {
      console.log(value);
    },
    changeType(value) {
      console.log(value);
      let that = this;
      that.itemzhaopin.TypeSysID = value;
    },
    // 编辑招聘信息
    zhaopinedit(formName) {
      let that = this;
      let item = that.itemzhaopin;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          // item.TypeName = "";
          if (item.Tags.length > 1) {
            item.Tags = item.Tags.join(",");
          } else {
            item.Tags = item.Tags[0];
          }
          // let formData = new FormData();
          // formData.append("Obj", JSON.stringify(item));
          that.$post("/api/Advertise/Edit", item).then(function (data) {
            console.log(data);
            if (data.Result) {
              that.$message.success("编辑招聘信息成功");

              that.getzhabiaoList();
              that.i = 1;
              that.itemzhaopin = {};
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 添加招聘信息
    addzhaopin(formName) {
      let that = this;
      let CompanySysID = window.localStorage.getItem("CompanySysID");
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.addzhaopinlist);

          let item = that.addzhaopinlist;
          item.TypeSysID = item.TypeName;
          if (item.Tags.length > 1) {
            item.Tags = item.Tags.join(",");
          } else {
            item.Tags = item.Tags[0];
          }
          item.State = true;
          item.CompanySysID = CompanySysID;

          that.$post("/api/Advertise/Edit", item).then(function (data) {
            console.log(data);
            if (data.Result) {
              that.$message.success("发布职位成功");
              that.getzhabiaoList();
              that.i = 1;
              that.addzhaopinlist = {};
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getperson() {
      this.$router.push("/personalCenter");
    },
    getnews() {
      this.$router.push("/newsCenter");
    },
    getcarrier() {
      this.$router.push("/carrierCenter");
    },
    // 服务
    gettags(i, item) {
      console.log(item);
      this.i = i;
      if (item) {
        this.item = item;
      }
    },
    // 招聘
    gettagszhao(i, item) {
      console.log(item);
      this.i = i;
      if (item) {
        if (item.Tags&&item.Tags.indexOf(",") > -1) {
          item.Tags = item.Tags.split(",");
        } else if (item.Tags&&item.Tags.indexOf(",") == -1) {
          let arr = [];
          arr.push(item.Tags);
          item.Tags = arr;
        }
        this.itemzhaopin = item;

        this.zhaopin = item;
      }
    },
    getgongxuItem(i, item) {
      console.log(item);
      this.i = i;
      if (item) {
        this.itemgongxu = item;
        item.Type = item.Type ? "需求" : "供应";
        this.gongxu = item;
      }
    },

    getgongxuEditor(value) {
      this.gongxu.Description = value;
    },

    handlePictureCardPreview(file) {
      console.log(file);
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 营业执照
    submitUpload() {
      this.$refs.upload.submit();
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
  },

  mounted() {
    this.getnewsList();

    // 企业服务类型
    this.getservetype();
    // 招标
    this.getzhabiaoList();

    // 招聘类型
    this.getzhaopintype();
    this.getFL();
  },
};
</script>
<style scoped  lang="scss">
.page {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.userinforbox {
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
}

// .bottom_right ::v-deep .el-select .el-input__inner {
//   width: 320px;
// }
.htmlbox {
  float: left;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
}
.htmlbox ::v-deep img {
  width: 100%;
}
.htmlbox ::v-deep p {
  line-height: 30px;
  font-size: 15px;
  color: #555;
}
.htmlbox ::v-deep dt {
  line-height: 40px;
  font-weight: 600;
  font-size: 15px;
  color: #555;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.textbox {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #f6f6f6;
}
.topbox {
  width: 100%;
  background-color: #fff;
  height: 100px;
  margin-top: 30px;
}
.topboxinfor {
  width: 1200px;
  height: 100%;
  margin-left: 50%;
  transform: translateX(-50%);
  // background-color: aquamarine;
}
.topboxinfor img {
  float: left;
  height: 60px;
  margin-top: 20px;
  margin-right: 40px;
}
.top_btnbox {
  position: relative;
  top: 0;
  float: left;
  margin-left: 20px;
  height: 100px;
  width: 100px;
  cursor: pointer;
  // background-color: red;
  em {
    position: absolute;
    font-style: none;
    height: 20px;
    line-height: 20px;
    width: 20px;
    right: 10px;
    top: 10px;
    border-radius: 50%;
    background-color: red;
    font-size: 10px;
    color: #fff;
    text-align: center;
  }
  img {
    position: absolute;
    height: 40px;
    width: 40px;
    left: 30px;
    top: 10px;
    margin: 0;
  }
  span {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 60px;
    height: 30px;
    line-height: 30px;
    color: #1890ff;
    font-size: 15px;
  }
}
.bottombox {
  width: 1200px;
  min-height: 600px;
  margin-left: 50%;
  margin-top: 20px;
  transform: translateX(-50%);
  // background-color: aquamarine;
  overflow: hidden;
}
.bottom_left {
  float: left;
  // background-color: red;
  width: 300px;
  min-height: 600px;
}
.bottom_right {
  float: left;
  width: 880px;
  margin-left: 20px;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #eee;
  min-height: 600px;
  box-sizing: border-box;
}



.active1 {
  background-color: #e9e9e9;
}
.huanying {
  height: 60px;
  line-height: 30px;
  padding: 15px;
  width: 100%;
  background-color: #d9edf7;
  box-sizing: border-box;
  font-size: 14px;
  color: #666;
}
.smalltitle {
  position: relative;
  height: 36px;
  line-height: 6px;
  font-size: 14px;
  background-color: #f1f1f1;
  margin-top: 15px;
  padding: 15px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.heard {
  position: relative;
  height: 30px;
  width: 100%;
  span {
    cursor: pointer;
    position: absolute;
    height: 30px;
    width: 100px;
    border-radius: 3px;
    color: #1890ff;
    font-size: 14px;
    line-height: 32px;
    background-color: #fff;
    vertical-align: middle;
    img {
      display: inline-block;
      height: 30px;
      width: 30px;
      // vertical-align:middle;
      vertical-align: middle;
    }
  }
}
.userinforbox {
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding: 15px;
}
.username {
  // display: inline-block;
  float: left;
  min-width: 50%;
  max-width: 100%;
  padding: 10px 15px;
  height: 60px;
  line-height: 40px;
  font-size: 14px;
  color: #63cafd;
  box-sizing: border-box;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  span {
    display: inline-block;
    height: 40px;
    width: 120px;
    line-height: 40px;
    color: #666;
    // background-color: #D9EDF7;
  }
  i {
    font-style: normal;
  }
}
.usertitle {
  float: left;
  width: 100%;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  margin-top: 20px;
  padding: 0 15px;
  box-sizing: border-box;
}
.userlogo {
  height: 100px;
  // font-weight: 700;

  line-height: 100px;
  font-size: 16px;

  span {
    display: inline-block;
    width: 100px;
    font-size: 14px;
    //  padding: 15px;
    color: #666;
  }
  img {
    height: 100px;
    // width: 80px;
    vertical-align: middle;
    margin-right: 30px;
  }
}
.inforedint {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  background-color: #f1f1f1;
  span {
    cursor: pointer;
    background-color: #1890ff;
    padding: 10px 30px;
    color: #fff;
    font-size: 15px;
    border-radius: 3px;
  }
}

.password {
  display: flex;
  align-items: center;
  // height: 80px;
  padding: 15px 0;
  border-bottom: 1px solid #eee;
}
.left {
  flex: 6;
  .left_title {
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    font-weight: 600;
    padding-left: 15px;
    box-sizing: border-box;
    color: #555;
  }
  .left_text {
    color: #555;
    line-height: 30px;
    font-size: 14px;
    padding-left: 15px;
    box-sizing: border-box;
  }
}
.right {
  flex: 1;

  span {
    background-color: #1890ff;
    cursor: pointer;
    padding: 3px 20px;
    color: #fff;
    font-size: 15px;
    border-radius: 3px;
  }
}
@media (max-width: 500px) {
.tabbox{
  width: 900px;
}
  .page {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.userinforbox {
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
}

// .bottom_right ::v-deep .el-select .el-input__inner {
//   width: 320px;
// }
.htmlbox {
  float: left;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
}
.htmlbox ::v-deep img {
  width: 100%;
}
.htmlbox ::v-deep p {
  line-height: 30px;
  font-size: 15px;
  color: #555;
}
.htmlbox ::v-deep dt {
  line-height: 40px;
  font-weight: 600;
  font-size: 15px;
  color: #555;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.textbox {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  background-color: #f6f6f6;
}
.topbox {
  width: 100%;
  background-color: #fff;
  height: 100px;
  margin-top: 30px;
}
.topboxinfor {
  width: 1200px;
  height: 100%;
  margin-left: 50%;
  transform: translateX(-50%);
  // background-color: aquamarine;
}
.topboxinfor img {
  float: left;
  height: 60px;
  margin-top: 20px;
  margin-right: 40px;
}

.bottombox {
  width: 100%;
  min-height: 600px;
  margin-left: 50%;
  margin-top: 20px;
  transform: translateX(-50%);
  // background-color: aquamarine;
  overflow: auto;
}

.bottom_right {
  float: left;
  width: 100%;
  margin-left: 0;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #eee;
  min-height: 600px;
  box-sizing: border-box;
  overflow: auto;
}

.active1 {
  background-color: #e9e9e9;
}
.huanying {
  height: 60px;
  line-height: 30px;
  padding: 15px;
  width: 100%;
  background-color: #d9edf7;
  box-sizing: border-box;
  font-size: 14px;
  color: #666;
}
.smalltitle {
  position: relative;
  height: 36px;
  line-height: 6px;
  font-size: 14px;
  background-color: #f1f1f1;
  margin-top: 15px;
  padding: 15px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.heard {
  position: relative;
  height: 30px;
  width: 100%;
  span {
    cursor: pointer;
    position: absolute;
    height: 30px;
    width: 100px;
    border-radius: 3px;
    color: #1890ff;
    font-size: 14px;
    line-height: 32px;
    background-color: #fff;
    vertical-align: middle;
    img {
      display: inline-block;
      height: 30px;
      width: 30px;
      // vertical-align:middle;
      vertical-align: middle;
    }
  }
}
.userinforbox {
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding: 15px;
}
.username {
  // display: inline-block;
  float: left;
  min-width: 50%;
  max-width: 100%;
  padding: 10px 15px;
  height: 60px;
  line-height: 40px;
  font-size: 14px;
  color: #63cafd;
  box-sizing: border-box;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  span {
    display: inline-block;
    height: 40px;
    width: 120px;
    line-height: 40px;
    color: #666;
    // background-color: #D9EDF7;
  }
  i {
    font-style: normal;
  }
}
.usertitle {
  float: left;
  width: 100%;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  margin-top: 20px;
  padding: 0 15px;
  box-sizing: border-box;
}
.userlogo {
  height: 100px;
  // font-weight: 700;

  line-height: 100px;
  font-size: 16px;

  span {
    display: inline-block;
    width: 100px;
    font-size: 14px;
    //  padding: 15px;
    color: #666;
  }
  img {
    height: 100px;
    // width: 80px;
    vertical-align: middle;
    margin-right: 30px;
  }
}
.inforedint {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  background-color: #f1f1f1;
  span {
    cursor: pointer;
    background-color: #1890ff;
    padding: 10px 30px;
    color: #fff;
    font-size: 15px;
    border-radius: 3px;
  }
}

.password {
  display: flex;
  align-items: center;
  // height: 80px;
  padding: 15px 0;
  border-bottom: 1px solid #eee;
}
.left {
  flex: 6;
  .left_title {
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    font-weight: 600;
    padding-left: 15px;
    box-sizing: border-box;
    color: #555;
  }
  .left_text {
    color: #555;
    line-height: 30px;
    font-size: 14px;
    padding-left: 15px;
    box-sizing: border-box;
  }
}
.right {
  flex: 1;

  span {
    background-color: #1890ff;
    cursor: pointer;
    padding: 3px 20px;
    color: #fff;
    font-size: 15px;
    border-radius: 3px;
  }
}
}
</style>